import PostListItem from './index';

export const mapTypeToSize = (size) => {
  switch (size) {
    case PostListItem.SMALL:
      return {
        width: 300,
      };
    case PostListItem.MEDIUM:
      return {
        width: 458,
      };
    default:
      return {
        width: 600,
      };
  }
};
