import React, { FC } from 'react';
import { isNumber } from 'lodash';
import CommentIcon from 'wix-ui-icons-common/on-stage/Comment';
import VisibleIcon from 'wix-ui-icons-common/on-stage/Visible';

import { formatCount } from './stats-bar.utils';
import { classes, st } from './stats-bar.st.css';
import { StatComponent } from './stat-component';

interface StatsBarProps {
  isRTL?: boolean;
  reactions?: number;
  comments?: number;
  views?: number;
}

export const StatsBar: FC<StatsBarProps> = ({ isRTL = false, reactions, comments, views }) => {
  return (
    <div className={st(classes.container)}>
      {isNumber(reactions) && (
        <StatComponent isRTL={isRTL} value={formatCount(reactions)} iconComponent={ReactionsIcon} />
      )}
      {isNumber(comments) && (
        <StatComponent isRTL={isRTL} value={formatCount(comments)} iconComponent={CommentIcon} />
      )}
      {isNumber(views) && (
        <StatComponent isRTL={isRTL} value={formatCount(views)} iconComponent={VisibleIcon} />
      )}
    </div>
  );
};

// TODO: add to wix-ui-icons-common
export const ReactionsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 3C17.187 3 21 6.813 21 11.5C21 16.187 17.187 20 12.5 20C7.813 20 4 16.187 4 11.5C4 6.813 7.813 3 12.5 3ZM12.5 4C8.364 4 5 7.364 5 11.5C5 15.636 8.364 19 12.5 19C16.636 19 20 15.636 20 11.5C20 7.364 16.636 4 12.5 4ZM9.352 12.6248C9.933 13.8638 11.139 14.6338 12.5 14.6338C13.824 14.6338 15.016 13.8938 15.611 12.7008L16.505 13.1488C15.741 14.6818 14.206 15.6338 12.5 15.6338C10.773 15.6338 9.183 14.6188 8.447 13.0488L9.352 12.6248ZM9.5 8.5C10.052 8.5 10.5 8.947 10.5 9.5C10.5 10.053 10.052 10.5 9.5 10.5C8.948 10.5 8.5 10.053 8.5 9.5C8.5 8.947 8.948 8.5 9.5 8.5ZM15.5 8.5C16.052 8.5 16.5 8.947 16.5 9.5C16.5 10.053 16.052 10.5 15.5 10.5C14.948 10.5 14.5 10.053 14.5 9.5C14.5 8.947 14.948 8.5 15.5 8.5Z"
    />
  </svg>
);
