import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { getIsQaMode } from '../../../common/store/location/location-selectors';
import { RootState } from '../../types/store-types';
import { classes, st } from './stats-bar.st.css';

interface StatComponentProps {
  isRTL: boolean;
  value: number | string;
  iconComponent: React.FC<any>;
  isScreenshotMode?: boolean;
}

const StatComponentBase: React.FC<StatComponentProps> = ({
  isRTL,
  value,
  iconComponent: Icon,
  isScreenshotMode,
}) => {
  const styleStateProps = { rtl: isRTL };

  return (
    <div className={st(classes.statContainer, styleStateProps)}>
      <div className={st(classes.iconContainer, styleStateProps)}>
        <Icon size={20} />
      </div>
      <div>{isScreenshotMode ? '<#>' : value}</div>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState) => ({
  isScreenshotMode: getIsQaMode(state),
});

export const StatComponent = flowRight(connect(mapRuntimeToProps))(StatComponentBase);
